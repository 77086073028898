<template>
  <div>
    <p class="text-2xl mb-6">Liste des permissions</p>
    <v-col cols="12" class="d-flex flex-row-reverse">
      <v-btn color="primary" link :to="{ name: 'users-list' }" class="mx-1">
        <v-icon class="mr-3">{{ icons.list }}</v-icon
        >Utilisateurs</v-btn
      >
      <v-btn color="primary" link :to="{ name: 'roles-list' }" class="mx-1">
        <v-icon class="mr-3">{{ icons.list }}</v-icon
        >Roles</v-btn
      >
      <v-btn color="primary" link :to="{ name: 'permissions-create' }" class="mx-1">
        <v-icon class="mr-3">{{ icons.plus }}</v-icon
        >Créer une nouvelle permission</v-btn
      >
    </v-col>
    <v-col cols="12"> <permissions-table></permissions-table> </v-col>
  </div>
</template>

<script>
import { mdiPlusBox as plus, mdiViewListOutline as list } from "@mdi/js";
import PermissionsTable from "@/components/admin/users/permissions/tables/PermissionsTable.vue";

export default {
  name: "PermissionList",
  components: {
    PermissionsTable,
  },
  created() {},
  data() {
    return {
      icons: {
        plus,
        list
      },
    };
  },
  methods: {},
  watch: {},
};
</script>
<style scoped></style>
